//世界地图
<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <!-- <div v-if="!chartData.length" class="no-data">{{ $t('noData') }}</div> -->
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import '@/lib/world.js'
import { countryList } from '@/lib/countryList.js'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        // backgroundColor: 'rgba(0, 10, 52, 1)',
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return (
              `${params.marker}${params.name}<br/>` +
              `${this.$t('customerHome.totalDevicesNumber')}:${params.value || 0}<br/>` +
              `${this.$t('customerHome.onlineEquipment')}:${(params.data &&
                params.data.onlineNumber) ||
                0}<br/>` +
              `${this.$t('customerHome.offlineEquipment')}:${(params.data &&
                params.data.offlineNumber) ||
                0}<br/>`
            )
          },
          confine: true
        },
        visualMap: {
          min: 0,
          calculable: true,
          // text: [],
          textStyle: {
            color: '#fff'
          },
          type: 'continuous',
          show: true,
          orient: 'horizontal',
          bottom: 0,
          color: ['#074d29', '#ccebd6']
        },
        //配置属性
        series: [
          {
            name: '',
            type: 'map',
            mapType: 'world',
            roam: true,
            zoom: 1.2,
            scaleLimit: {
              min: 1,
              max: 15
            },
            aspectScale: 0.75,
            layoutCenter: ['50%', '51.5%'], //地图位置
            layoutSize: '118%',
            layoutCenter: ['50%', '50%'], //地图位置
            layoutSize: '100%',
            roam: true,
            geoIndex: 0,
            itemStyle: {
              normal: {
                borderColor: 'rgba(147, 235, 248, 0.6)',
                borderWidth: 0.8,
                areaColor: {
                  type: 'linear-gradient',
                  x: 0,
                  y: 1200,
                  x2: 1000,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#074d29' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#ccebd6' // 50% 处的颜色
                    }
                  ],
                  global: true // 缺省为 false
                }
              },
              emphasis: {
                areaColor: 'rgba(147, 235, 248, 0)'
              }
            },
            label: {
              normal: {
                show: false, //省份名称
                backgroundColor: 'transparent',
                color: '#fff',
                fontSize: 12
              },
              emphasis: {
                show: false
              }
            },
            emphasis: {
              itemStyle: {
                areaColor: ''
              }
            },
            data: [] //数据
          }
        ]
      }
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val && val.length) {
          const data = countryList
          const targetData = [...val]
          let numbers = []
          val.forEach(item => {
            numbers.push(item.deviceNumber)
            const index = data.findIndex(_ => {
              return _.code === item.countryName
            })
            if (index > -1 && item) {
              if (this.$i18n.locale == 'en') {
                data[index].name = data[index].en
              } else {
                data[index].name = data[index].ruRU
              }
              data[index].value = item.deviceNumber
              data[index].onlineNumber = item.onlineNumber
              data[index].offlineNumber = item.offlineNumber
            }
          })
          this.opt.visualMap.max = Math.max(...numbers)
          this.opt.series[0].data = data
        } else {
          this.opt.series[0].data = this.opt.series[0].data.map(a => {
            return { ...a, value: 0, onlineNumber: 0, offlineNumber: 0 }
          })
        }
      },
      deep: true
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: normal;
    color: #fff;
    font-size: 16px;
    padding: 30px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
