<template>
  <div class="wrap">
    <h3>{{ $t('customerHome.installAppStatistics') }} TOP10</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize v-if="chartData.length" />
      <div v-else class="no-data">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        color: [
          '#074d29',
          '#bf8962',
          '#8f83ff',
          '#de5dd0',
          '#68bfba',
          '#868e9e',
          '#f9687c',
          '#eaa5c5',
          '#f78a42',
          '#a6bc72'
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
          confine: true
        },
        legend: {
          // orient: 'vertical',
          data: [],
          icon: 'circle',
          itemWidth: 8,
          itemGap: 10,
          // top: 126,
          bottom: 10,
          textStyle: {
            color: '#333'
          }
        },
        series: [
          {
            type: 'pie',
            radius: '54%',
            center: ['50%', '50%'],
            data: [],
            roseType: 'area',
            label: {
              show: true,
              formatter: '{d}%',
              confine: true
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function(idx) {
              return Math.random() * 200
            }
          }
          // {
          //   name: '半径模式',
          //   type: 'pie',
          //   radius: [5, 90],
          //   center: ['40%', '50%'],
          //   roseType: 'radius',
          //   label: {
          //     show: true,
          //     formatter: '{d}%',
          //     confine: true
          //   },
          //   emphasis: {
          //     label: {
          //       show: true
          //     }
          //   },
          //   data: []
          // }
          // {
          //   name: '面积模式',
          //   type: 'pie',
          //   radius: [30, 110],
          //   center: ['75%', '50%'],
          //   roseType: 'area',
          //   data: []
          // }
        ]
      }
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val && val.length) {
          const legend = []
          const data = []
          val.forEach(item => {
            legend.push(item.appName || '-')
            data.push({
              name: item.appName || '-',
              value: item.deviceNumber
            })
          })
          this.opt.legend.data = legend
          this.opt.series[0].data = data
        }
      }
    },
    deep: true
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: normal;
    color: #333;
    font-size: 16px;
    padding: 30px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    background: url('../../../../../assets/img/circleLine.png') 50% 50% no-repeat;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
