import axios from '../lib/request.js'
import Qs from 'qs'

const api = {
  /* 设备列表 */

  //获取出库信息列表
  deviceRoomList: data => axios.post('/device/warehouseOut/list', data),

  //删除出库信息
  deviceRoomDelete: params => axios.delete('/device/warehouseOut/delete', { params }),

  //更换出库客户
  batchUpdateSpId: data => axios.post('/device/warehouseOut/batchUpdateSpId', data),

  //新增出库信息
  deviceRoomAdd: data => axios.post('/device/warehouseOut/add', data),

  //修改出库信息
  deviceRoomUpdate: data => axios.post('/device/warehouseOut/update', data),

  //批量新增出库信息
  deviceRoomImport: data =>
    axios.post('/device/warehouseOut/importDeviceWarehouseOut', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //检测批量新增出库重复
  checkRepetition: data =>
    axios.post('/device/warehouseOut/checkRepetition', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //根据条件查询设备信息列表
  deviceList: data => axios.post('/device/list', data),

  //获取设备详情外设列表
  peripheralList: data => axios.post('/device/peripheral/list', data),

  //获取设备详情app列表
  appList: data => axios.post('/device/app/list', data),

  //添加设备信息
  deviceAdd: data => axios.post('/device/add', data),

  //删除设备信息
  deviceDelete: data => axios.post('/device/delete', data),

  //根据客户ID查询单个设备信息
  deviceGet: params => axios.get('/device/get', { params }),

  //更新设备信息
  deviceUpdate: data => axios.post('/device/update', data),

  //获取日志
  deviceLogList: data => axios.post('/device/log/list', data),

  //实时获取设备的状态等信息
  deviceSendCommand: data => axios.post('/device/sendCommand', Qs.stringify(data)),

  //外设操作
  peripheralOperation: data => axios.post('/device/peripheral/peripheralOperation', data),

  //根据客户ID查询单个设备日志信息，根据该接口可以获取日志下载链接
  deviceLogGet: data =>
    axios.post('/device/log/get', data, { headers: { 'Content-Type': 'application/json' } }),

  //上传模板
  deviceUpload: data =>
    axios.post('/device/uploadTemplate', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //导入文件
  deviceImport: data =>
    axios.post('/device/importDevice', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //批量下载
  deviceDownload: params => axios.get('/device/downloadTemplate', { params }),

  //发送远程协助指令
  remoteAssistance: params => axios.get('/device/remoteAssistance', { params }),

  //文件取回
  retrieve: params => axios.get('/device/remoteFileTransfer', { params }),
  //文件取回下载地址
  getDownLoadUrl: code => axios.get(`/device/download?fileId=${code}`),

  /* 分组管理 */

  //根据条件查询分组信息列表
  groupList: data => axios.post('/group/list', data),

  //添加分组信息
  groupAdd: data => axios.post('/group/add', data),

  //删除分组信息
  groupDelete: data => axios.post('/group/delete', data),

  //根据设备分组ID查询单个分组信息
  groupGet: data => axios.post('/group/get', data),

  //更新分组信息
  groupUpdate: data => axios.post('/group/update', data),

  //从分组删除一个设备
  groupReomve: data => axios.post('/group/removeDevice', data),

  /* 设备型号管理 */

  //根据条件查询设备型号信息列表
  modelList: data => axios.post('/model/list', data),

  getOtaPlatform: params => axios.get('/publish/version/getOtaPlatform', { params }),

  //设备刷新
  deviceRefresh: params => axios.get('/device/refresh', { params }),

  //添加设备型号信息
  modelAdd: data =>
    axios.post('/model/add', data, { headers: { 'Content-Type': 'multipart/form-data' } }),

  //删除
  modelDelete: data => axios.post('/model/delete', data),

  //根据设备设备型号ID查询单个设备型号信息
  modelGet: data => axios.post('/model/get', data),

  //更新设备型号信息
  modelUpdate: data => axios.post('/model/update', data),

  //设备总数
  deviceTotal: () => axios.get('/device/total'),

  //设备型号名字
  getModelName: data => axios.post('/model/query', data),

  //分组名字
  getGrouplName: data => axios.post('/group/query', data),

  //设备名字
  getDeviceName: data => axios.post('/device/query', data),

  //全部激活设备
  getActivationList: data => axios.post('/device/activation/list', data),

  //获取激活统计数据: 今日新增&本周新增&本月新增&累计设备
  getActivationStatistics: data => axios.post('/device/activation/statistics', data),

  //外设管理
  publishRemoteAssistance: data => axios.post('/publish/peripheralManagement', data),

  //取消霸屏
  cancelShow: params => axios.get('/device/cancelShow', { params }),

  //取消自启动
  cancelPowerBoot: params => axios.get('/device/cancelPowerBoot', { params }),

  //卸载APP
  uninstallApp: data => axios.post('/publish/uninstallApp', data),

  //外设配置列表
  configurationList: data => axios.post('/device/peripheral/configuration/list', data),

  //外设配置保存
  configurationSave: data => axios.post('/device/peripheral/configuration/add', data),

  //外设配置编辑
  configurationUpdate: data => axios.post('/device/peripheral/configuration/update', data),

  //外设配置删除
  configurationDelete: params =>
    axios.delete('/device/peripheral/configuration/delete', { params }),

  //获取外设种类
  getByPeripheralType: data =>
    axios.post('/device/peripheral/configuration/getByPeripheralType', data),

  //分组树状图
  getGroupTree: data => axios.post('/group/getGroupTree', data),

  //分组添加(子分组添加)
  groupAddChild: data => axios.post('/group/add', data),

  //分组修改)
  groupUpdateChild: data => axios.post('/group/update', data),

  //分组添加(子分组添加)
  groupDel: data => axios.post('/group/delete', data),

  //移动分组
  batchUpdate: data => axios.post('/group/batchUpdate', data),

  //定时重启
  restStart: data => axios.post('/device/scheduled/tasks/batchAdd', data),

  //批量修改分组
  editBatchGroup: data => axios.post('/group/updateSnGroup', data),

  //定时重启详情
  getRestStartDetail: data => axios.post('/device/scheduled/tasks/listBySn', data),

  //根据sn~查询定时重启任务
  getRestStartList: data => axios.post('/device/scheduled/tasks/taskListBySn', data),

  //根据sn~删除定时重启任务
  delTaskBySn: data => axios.post('/device/scheduled/tasks/deleteBySn', data),

  //获取设备位置
  getLocation: sn => axios.get(`/device/getAddrDetailByAndroid?sn=${sn}`),

  //根据sn~限制本地安装
  closeAppLock: data => axios.post('/device/closeAppLock', data),

  //根据sn~允许本地安装
  openAppLock: data => axios.post('/device/openAppLock', data),

  //获取签名校验类型
  getSignList: data => axios.get('/model/getSign'),
  //客户平台最大可上传文件大小
  deviceGetTransferFilesMaxSize: data => axios.get('/device/getTransferFilesMaxSize'),
  //管理平台最大可上传文件大小
  modelGetTransferFilesMaxSize: data => axios.get('/model/getTransferFilesMaxSize'),
  //新增删除ota升级黑名单:type=>1:新增，0:删除
  updateOtaStatus: data => axios.post(`/device/updateOtaStatus/${data.type}`, data.arr)
}

export default api
