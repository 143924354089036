<template>
  <div class="wrap">
    <h3>{{ $t('adminHomePage.devRegion') }} TOP10</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize v-if="chartData.length" />
      <div v-else class="no-data">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        grid: {
          left: 130,
          right: 80,
          bottom: 20
        },
        color: ['#074d29', '#bf8962', '#8f83ff', '#de5dd0', '#68bfba', '#868e9e'],
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return `
              ${`${params.marker}${params.name}：${params.value} (${
                this.ratioArray[params.dataIndex]
              })`}`
          },
          confine: true
        },

        xAxis: {
          type: 'value',
          show: false,
          splitLine: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#333',
              align: 'left'
            },
            offset: 100,
            data: []
          },
          {
            type: 'category',
            inverse: true,
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#ffbb17'
            },
            data: []
          }
        ],
        series: {
          data: [],
          type: 'bar',
          barWidth: 6,
          label: {
            show: false
          },
          showBackground: true,
          backgroundStyle: {
            color: '#f6f6f6',
            barBorderRadius: [10, 10, 10, 10]
          },
          itemStyle: {
            color: '#ffbb17',
            barBorderRadius: [10, 10, 10, 10]
          }
        }
      },
      ratioArray: []
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val && val.length) {
          const targetData = [...val]
          const yData = []
          const data = []
          const ratioArray = []

          this.totalNumber = targetData.reduce((num, item) => {
            return (num += item.deviceNumber)
          }, 0)

          targetData
            .sort((a, b) => {
              return b.deviceNumber - a.deviceNumber
            })
            .forEach((item, index) => {
              yData.push(`TOP${index + 1} ` + (item.regionName || '-'))
              data.push(item.deviceNumber)
              ratioArray.push(item.ratio)
            })

          this.opt.yAxis[0].data = yData
          this.opt.yAxis[1].data = data
          this.opt.series.data = data
          this.ratioArray = ratioArray
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;
  h3 {
    font-weight: normal;
    color: #333;
    font-size: 16px;
    padding: 30px 20px;
  }
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
