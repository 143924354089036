//中国地图
<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <!-- <div v-if="!chartData.length" class="no-data">{{ $t('noData') }}</div> -->
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import '@/lib/china.js'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        // backgroundColor: '#333333',
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return (
              `${params.marker}${params.name}<br/>` +
              `总设备数:${params.value || 0}<br/>` +
              `在线设备:${(params.data && params.data.onlineNumber) || 0}<br/>` +
              `离线设备:${(params.data && params.data.offlineNumber) || 0}<br/>`
            )
          },
          confine: true
        },
        visualMap: {
          min: 0,
          calculable: true,
          // text: [],
          textStyle: {
            color: '#fff'
          },
          type: 'continuous',
          show: true,
          orient: 'horizontal',
          bottom: 0,
          color: ['#074d29', '#ccebd6']
        },

        //配置属性
        series: [
          {
            name: '数据',
            type: 'map',
            mapType: 'china',
            roam: true,
            zoom: 1.2,
            scaleLimit: {
              min: 1,
              max: 10
            },
            label: {
              normal: {
                show: true, //省份名称
                backgroundColor: '#fff',
                fontSize: 13
              },
              emphasis: {
                show: false
              }
            },
            emphasis: {
              itemStyle: {
                areaColor: ''
              }
            },
            data: [
              { name: '北京', value: 0 },
              { name: '天津', value: 0 },
              { name: '上海', value: 0 },
              { name: '重庆', value: 0 },
              { name: '河北', value: 0 },
              { name: '河南', value: 0 },
              { name: '云南', value: 0 },
              { name: '辽宁', value: 0 },
              { name: '黑龙江', value: 0 },
              { name: '湖南', value: 0 },
              { name: '安徽', value: 0 },
              { name: '山东', value: 0 },
              { name: '新疆', value: 0 },
              { name: '江苏', value: 0 },
              { name: '浙江', value: 0 },
              { name: '江西', value: 0 },
              { name: '湖北', value: 0 },
              { name: '广西', value: 0 },
              { name: '甘肃', value: 0 },
              { name: '山西', value: 0 },
              { name: '内蒙古', value: 0 },
              { name: '陕西', value: 0 },
              { name: '吉林', value: 0 },
              { name: '福建', value: 0 },
              { name: '贵州', value: 0 },
              { name: '广东', value: 0 },
              { name: '青海', value: 0 },
              { name: '西藏', value: 0 },
              { name: '四川', value: 0 },
              { name: '宁夏', value: 0 },
              { name: '海南', value: 0 },
              { name: '台湾', value: 0 },
              { name: '香港', value: 0 },
              { name: '澳门', value: 0 }
            ] //数据
          }
        ]
      }
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val && val.length) {
          const data = [...this.opt.series[0].data]
          const targetData = [...val]
          let numbers = []
          val.forEach(item => {
            numbers.push(item.deviceNumber)
            let provicneName = ''
            if (item.province.includes('自治区') || item.province.includes('行政区')) {
              provicneName =
                item.province == '内蒙古自治区' ? '内蒙古' : item.province.substring(0, 2)
              console.log('provicneName', provicneName)
              // if(item.province=='内蒙古')
            } else {
              provicneName = item.province.replace('省', '').replace('市', '')
            }

            const index = data.findIndex(_ => _.name === provicneName)

            if (index > -1 && item) {
              data[index].value = item.deviceNumber
              data[index].onlineNumber = item.onlineNumber
              data[index].offlineNumber = item.offlineNumber
            }
          })
          this.opt.visualMap.max = Math.max(...numbers)

          console.log('data', data)

          this.opt.series[0].data = data
        } else {
          this.opt.series[0].data = this.opt.series[0].data.map(a => {
            return { ...a, value: 0, onlineNumber: 0, offlineNumber: 0 }
          })
        }
      }
    },
    deep: true
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: normal;
    color: #fff;
    font-size: 16px;
    padding: 30px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
