<template>
  <div class="wrap">
    <h3>{{ $t('customerHome.warning7Days') }}</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <div v-if="!chartData.length" class="no-data">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        color: ['#074d29', '#bf8962', '#8f83ff', '#de5dd0', '#68bfba', '#868e9e'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          top: 120,
          bottom: 30,
          right: 40
        },
        legend: {
          textStyle: {
            color: '#333'
          },
          top: 55,
          itemGap: 54,
          itemHeight: 2,
          itemWidth: 20,
          icon: 'rect',
          data: [
            this.$t('deviceList.cpuTemperature'),
            this.$t('customerHome.runnMemory'),
            this.$t('customerHome.storageCapacity')
          ]
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          axisLabel: {
            color: '#999'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          // data: ['1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7']
          data: []
        },
        yAxis: {
          type: 'value',
          name: this.$t('adminHomePage.unit') + ':' + this.$t('customerHome.second'),
          nameTextStyle: {
            color: '#999'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#999'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#999'
          },
          splitLine: {
            lineStyle: {
              color: '#999'
            }
          },
          minInterval: 1
        },
        series: [
          {
            name: this.$t('deviceList.cpuTemperature'),
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    color: '#074d29',
                    offset: 0
                  },
                  {
                    offset: 1,
                    color: '#415427'
                  }
                ]
              },
              opacity: 0.3
            },
            data: []
            // data: [37, 30, 35, 32, 28, 30, 25]
          },
          {
            name: this.$t('customerHome.runnMemory'),
            type: 'line',
            symbol: 'none',
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    color: '#ffbb17',
                    offset: 0
                  },
                  {
                    offset: 1,
                    color: '#453d29'
                  }
                ]
              },
              opacity: 0.3
            },
            data: []
            // data: [44, 45, 50, 46, 32, 38, 40]
          },
          {
            name: this.$t('customerHome.storageCapacity'),
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    color: '#de5dd0',
                    offset: 0
                  },
                  {
                    offset: 1,
                    color: '#3e313d'
                  }
                ]
              },
              opacity: 0.3
            },
            data: []
            // data: [9, 20, 18, 35, 32, 28, 27]
          }
        ]
      }
    }
  },
  watch: {
    chartData: {
      handler(val) {
        if (val && val.length) {
          const data = [...val]
          const cpuData = []
          const memoryData = []
          const storageData = []
          const xAxisData = []
          val.forEach(item => {
            cpuData.push(item.cpuNumber)
            memoryData.push(item.memoryNumber)
            storageData.push(item.storageNumber)
            xAxisData.push(item.date)
          })
          this.opt.series[0].data = cpuData
          this.opt.series[1].data = memoryData
          this.opt.series[2].data = storageData
          this.opt.xAxis.data = xAxisData
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;
  h3 {
    font-weight: normal;
    color: #333;
    font-size: 16px;
    padding: 20px 20px;
  }
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
